import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleApiError = (error) => {
        if (error.response && error.response.status === 401) {
          // Token has expired or is invalid
          logout(); // Implement this function to clear user data and redirect to login
          toast.error('Your session has expired. Please log in again.');
        } else {
          // Handle other types of errors
          toast.error('An error occurred. Please try again later.');
        }
      };

    const fetchUserData = async (token) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/me/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const userData = await response.json();
                setUser({
                    ...userData,
                    photoURL: userData.picture || '',
                    displayName: userData.username || userData.email,
                    credits: typeof userData.credits === 'number' ? userData.credits : 0
                });
                console.log('User data fetched successfully:', userData);
            } else if (response.status === 404) {
                console.warn('User data endpoint not found. Using stored user data.');
                // If the /api/users/me/ endpoint doesn't exist, we'll use the data from localStorage
                const storedUser = JSON.parse(localStorage.getItem('user'));
                if (storedUser) {
                    setUser(storedUser);
                } else {
                    throw new Error('No stored user data found');
                }
            } else {
                throw new Error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            // Don't set user to null here, as we might have user data from localStorage
            const storedUser = JSON.parse(localStorage.getItem('user'));
            if (storedUser) {
                setUser(storedUser);
            } else {
                setUser(null);
            }
        } finally {
            setLoading(false);
        }
    };


    const login = useCallback(async (data) => {
        console.log('Login data received:', data);
        if (data.access) {
            localStorage.setItem('accessToken', data.access);
        }
        if (data.refresh) {
            localStorage.setItem('refreshToken', data.refresh);
        }
        if (data.user) {
            const userData = {
                ...data.user,
                photoURL: data.user.picture || '',
                displayName: data.user.username || data.user.email,
                credits: typeof data.user.credits === 'number' ? data.user.credits : 0
            };
            setUser(userData);
            localStorage.setItem('user', JSON.stringify(userData));
            setLoading(false);
            toast.success('Logged in successfully!');
            navigate('/generate');
        } else {
            await fetchUserData(data.access);
        }
    }, [navigate]);

    const logout = useCallback(async () => {
        try {
          await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/logout/`, {
            method: 'POST',
            credentials: 'include',
          });
        } catch (error) {
          console.error('Logout error:', error);
        } finally {
          localStorage.removeItem('user');
          setUser(null);
          setLoading(false);
          navigate('/');
        }
      }, [navigate]);
      const refreshToken = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/token/refresh/`, {
            method: 'POST',
            credentials: 'include',
          });
          if (!response.ok) throw new Error('Token refresh failed');
          // The new token will be automatically set in cookies
        } catch (error) {
          console.error('Token refresh error:', error);
          logout(); // Implement this function to clear user data and redirect to login
        }
      };

    const checkAuth = useCallback(async () => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            try {
                await fetchUserData(token);
            } catch (error) {
                console.error('Error checking auth:', error);
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    const updateUserCredits = (newCredits) => {
        setUser(prevUser => {
            const updatedUser = {
                ...prevUser,
                credits: typeof newCredits === 'number' ? newCredits : (prevUser?.credits || 0)
            };
            localStorage.setItem('user', JSON.stringify(updatedUser));
            return updatedUser;
        });
    };

    return (
        <UserContext.Provider value={{ user, loading, login, logout, checkAuth, updateUserCredits }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);