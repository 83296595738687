import React, { useState } from 'react';
import { FaGoogle } from 'react-icons/fa'; 
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../../UserContext';
import './Auth.css'; 

const SignIn = ({ onClose, setCurrentView, onSignIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Please fill in all fields');
      return;
    }
    try {
      await onSignIn(email, password);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        console.log('Google token:', tokenResponse);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/token-exchange/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ access_token: tokenResponse.access_token }),
          credentials: 'include',
        });
  
        const responseText = await response.text();
        console.log('Full response:', responseText);
  
        if (!response.ok) {
          let errorMessage;
          try {
            const errorData = JSON.parse(responseText);
            errorMessage = errorData.detail || 'Google login failed';
          } catch (e) {
            errorMessage = 'Unexpected server response';
          }
          throw new Error(errorMessage);
        }
  
        const data = JSON.parse(responseText);
        await login(data);
        onClose();
        toast.success('Logged in successfully with Google!');
        navigate('/generate');
      } catch (error) {
        console.error('Google login error:', error);
        toast.error(`Google login failed: ${error.message}`);
      }
    },
    onError: (error) => {
      console.error('Google login error:', error);
      toast.error('Google login failed. Please try again.');
    },
  });

  return (
    <div className="auth-popup">
      <h2>Welcome Back!</h2>
      <button className="close-btn" onClick={onClose}>X</button>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <p className="link" onClick={() => setCurrentView('forgotPassword')}>Forgot Password?</p>
        <button type="submit">Sign In</button>
      </form>
      <hr className="divider" />
      <button className="google-btn" onClick={() => handleGoogleLogin()}>
        <FaGoogle /> Continue with Google
      </button>
      <p>Don't have an account? <span onClick={() => setCurrentView('signup')} className="link">Sign Up</span></p>
    </div>
  );
};

export default SignIn;