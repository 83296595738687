import React, { useState } from 'react';
import './Auth.css';

const ForgotPassword = ({ onClose, setCurrentView }) => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(['', '', '', '']);
  const [codeSent, setCodeSent] = useState(false);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    console.log('Email submitted:', email);
    setCodeSent(true);
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleVerifyOtp = () => {
    console.log('OTP verified:', otp.join(''));
    setCurrentView('resetPassword');
  };

  return (
    <div className="auth-popup">
      {!codeSent ? (
        <>
          <h2>Reset Password</h2>
          <p>Enter your email address that is associated with your account.</p>
          <form onSubmit={handleEmailSubmit}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Continue</button>
          </form>
        </>
      ) : (
        <>
          <h2>Get Your Code</h2>
          <p>Please enter the 4 digit code sent to your mail address.</p>
          <div className="otp-inputs">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
              />
            ))}
          </div>
          <p>Didn't get the code? <span className="link">Resend</span></p>
          <button onClick={handleVerifyOtp}>Verify and Proceed</button>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;