
import React from 'react';
import NavBar from '../NavBar/NavBar';


const DefaultLayout = ({ children }) => (
  <>
    <NavBar />
    {children}

  </>
);

export default DefaultLayout;