import React, { useRef, useState } from 'react';
import './Constraints.css';
import { AnimatedBeam } from './AnimatedBeam';

const constraintsData = [
  {
    title: "Text caption",
    description: "Sometimes the text caption created are all jambled non-english words. and we are aware of it and are actively working on fixing that problem. So you can choose the image you like, with the captions you want."
  },
  {
    title: "Your own face and style",
    description: "We are not taking your images as an input at the moment, so you cannot use it in your thumbnail. But if you desire we can create a custom model for you. Contact us for that."
  },
  {
    title: "Enhanced Customization",
    description: "Sometimes you love a thumbnail that we generated but just wish that one thing was different. Soon you will be able to select the things that you didn't like in the thumbnail and make specific changes to them."
  },
  {
    title: "Enhanced Customization",
    description: "We're building powerful tools that will let you fine-tune every aspect of your AI-generated thumbnails, ensuring they perfectly align with your vision and your branding."
  }
];

const Constraints = () => {
  const containerRef = useRef(null);
  const centerRef = useRef(null);
  const cardRefs = useRef([]);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await fetch('https://api.aithumbnail.in/email/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setSubmitMessage('Thank you for subscribing!');
        setEmail('');
      } else {
        setSubmitMessage(data.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      setSubmitMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="constraints" className="constraints-section">
    <div className="constraints-container" ref={containerRef}>
      <h2 className="constraints-heading">Our limitations (no one is without fault)</h2>
      <div className="constraints-content">
        {constraintsData.map((constraint, index) => (
          <div key={index} className="constraint-card" ref={el => cardRefs.current[index] = el}>
            <h3>{constraint.title}</h3>
            <p>{constraint.description}</p>
          </div>
        ))}
      </div>
      <div ref={centerRef} style={{ position: 'absolute', top: '50%', left: '50%' }}></div>
      {cardRefs.current.map((cardRef, index) => (
        <AnimatedBeam key={index} containerRef={containerRef} fromRef={centerRef} toRef={cardRef} />
      ))}
      <div className="constraints-footer">
        <p>Be the first to know when these exciting features launch!</p>
        <form onSubmit={handleSubmit} className="email-form-subscribe">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
            required
          />
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Get Updates'}
          </button>
        </form>
        {submitMessage && <p className="submit-message">{submitMessage}</p>}
      </div>
    </div>
  </section>
  );
};

export default Constraints;
