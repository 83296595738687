import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import "./NavBar.css";
import { Link as ScrollLink } from 'react-scroll';
import { useUser } from '../../UserContext';
import AuthPopup from "../Auth/AuthPopup";
import { User, ChevronDown } from 'react-feather';

export const NavBar = () => {
    const [scrolled, setScrolled] = useState(false);
    const [activeSection, setActiveSection] = useState('home');
    const { user, loading, logout, checkAuth } = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showAuthPopup, setShowAuthPopup] = useState(false);
    const [authMode, setAuthMode] = useState('signin');
    const [userCredits, setUserCredits] = useState([]);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }

            const sections = ['home', 'features', 'pricing', 'constraints'];
            for (let section of sections) {
                const element = document.getElementById(section);
                if (element && window.scrollY >= element.offsetTop - 100) {
                    setActiveSection(section);
                }
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    const fetchUserCredits = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                throw new Error('No access token found');
            }

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/credits/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Unauthorized: Please log in again');
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const credits = await response.json();
            console.log('User credits:', credits);
            setUserCredits(credits);
        } catch (error) {
            console.error('Error fetching user credits:', error.message);
            if (error.message === 'Unauthorized: Please log in again') {
                logout();
                navigate('/');
                toast.error('Your session has expired. Please log in again.');
            } else {
                toast.error(`Failed to fetch credits: ${error.message}`);
            }
        }
    };

    useEffect(() => {
        if (user) {
            fetchUserCredits();
        }
    }, [user]);

    const openAuthPopup = (mode) => {
        console.log('openAuthPopup called with mode:', mode);
        setAuthMode(mode);
        setShowAuthPopup(true);
    };

    const closeAuthPopup = () => {
        console.log('closeAuthPopup called');
        setShowAuthPopup(false);
    };

    const handleNavClick = (event, section) => {
        if (user && location.pathname === '/generate') {
            event.preventDefault();
            navigate('/', { state: { scrollTo: section } });
        }
    };

    const handleLogout = () => {
        logout();
        navigate('/');
        toast.info('Logged out successfully');
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showDropdown && !event.target.closest('.user-profile-dropdown')) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showDropdown]);
    const scrollToPricing = () => {
        const pricingSection = document.getElementById('pricing');
        if (pricingSection) {
            pricingSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
           <Navbar expand="md" className={scrolled ? "navbar scrolled" : "navbar"}>
                <div className="navbar-container">
                    <Link to="/" className="navbar-brand">AIThumbnail</Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="navbar-toggler-icon"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            {['home', 'features', 'pricing', 'constraints'].map((section) => (
                                <ScrollLink 
                                    key={section}
                                    to={section}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    className={`nav-link ${activeSection === section ? 'active' : ''}`}
                                    onClick={(e) => handleNavClick(e, section)}
                                >
                                    {section.charAt(0).toUpperCase() + section.slice(1)}
                                </ScrollLink>
                            ))}
                        </Nav>
                        <div className="auth-buttons">
                            {loading ? (
                                <div className="loading">Loading...</div>
                            ) : user ? (
                                <div className="user-profile-dropdown" onClick={toggleDropdown}>
                                    <User size={24} color="white" />
                                    <span className="user-name">
                                        {user.first_name && user.last_name
                                            ? `${user.first_name} ${user.last_name}`
                                            : user.displayName || user.username || user.email}
                                    </span>
                                    <ChevronDown size={20} color="white" />
                                    {showDropdown && (
                                        <div className="dropdown-menu">
                                            <Link to="/generate" className="dropdown-item">Generate</Link>
                                            <div className="dropdown-item">
                                                Credits:
                                                {userCredits.length > 0 ? (
                                                    userCredits.map(credit => (
                                                        <div key={credit.id}>
                                                            {credit.credit_type}: {credit.amount}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <>
                                                        <div>You have no credits :(</div>
                                                        <button onClick={scrollToPricing} className="buy-now-btn">Buy Now</button>
                                                    </>
                                                )}
                                            </div>
                                            <div className="dropdown-item" onClick={handleLogout}>Logout</div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <button className="auth-btn signin" onClick={() => openAuthPopup('signin')}>Sign In</button>
                                    <button className="auth-btn signup" onClick={() => openAuthPopup('signup')}>Sign Up</button>
                                </>
                            )}
                        </div>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            {showAuthPopup && <AuthPopup onClose={closeAuthPopup} initialView={authMode} />}
        </>
    );
};

export default NavBar;