import React from 'react';
import { useUser } from '../../UserContext';
import { toast } from 'react-toastify';

const SubscriptionHandler = ({ planId, amount, onSuccess, onError }) => {
    const { user, refreshToken, logout } = useUser();

    const getAccessToken = async () => {
        let token = localStorage.getItem('accessToken');
        if (!token) {
            // Try to refresh the token if access token is missing
            try {
                token = await refreshToken();
            } catch (error) {
                console.error('Failed to refresh token:', error);
                logout(); // Force logout if refresh fails
                throw new Error('Authentication failed. Please log in again.');
            }
        }
        return token;
    };

    const initiateSubscription = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/subscriptions/subscribe/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ plan_id: planId }),
            });

            if (response.status === 401) {
                // Token might be expired, try to refresh
                await refreshToken();
                // Retry the request with the new token
                return initiateSubscription();
            }

            if (!response.ok) {
                throw new Error('Failed to initiate subscription');
            }

            const data = await response.json();
            openRazorpayModal(data);
        } catch (error) {
            console.error('Error initiating subscription:', error);
            onError(error.message);
        }
    };

    const openRazorpayModal = (orderData) => {
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID,
            amount: orderData.amount,
            currency: "USD",
            name: "AIThumbnail",
            description: "Plan Subscription",
            order_id: orderData.order_id,
            handler: function (response) {
                confirmPayment(response);
            },
            prefill: {
                name: user.displayName,
                email: user.email,
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    const confirmPayment = async (paymentResponse) => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/subscriptions/confirm_payment/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    payment_id: paymentResponse.razorpay_payment_id,
                    order_id: paymentResponse.razorpay_order_id,
                    signature: paymentResponse.razorpay_signature,
                    plan_id: planId,
                }),
            });

            if (response.status === 401) {
                // Token might be expired, try to refresh
                await refreshToken();
                // Retry the request with the new token
                return confirmPayment(paymentResponse);
            }

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.detail || 'Payment verification failed');
            }

            if (data.status === 'Payment successful') {
                toast.success('Subscription successful!');
                onSuccess(data.subscription);
            } else {
                throw new Error('Payment verification failed');
            }
        } catch (error) {
            console.error('Error confirming payment:', error);
            onError(error.message);
        }
    };

    return (
        <button onClick={initiateSubscription}>Subscribe Now</button>
    );
};

export default SubscriptionHandler;