import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import './PreLaunch.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';

const PreLaunch = () => {
  const lettersRef = useRef([]);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  useEffect(() => {
    const letters = lettersRef.current;
    gsap.set(letters, { y: -200, opacity: 0 });
    
    gsap.to(letters, {
      y: 0,
      opacity: 1,
      duration: 1,
      ease: "bounce.out",
      stagger: 0.1
    });
  }, []);

  const comingSoonText = ["COMING", "SOON!"];

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await fetch('https://api.aithumbnail.in/email/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setSubmitMessage('Thank you for subscribing!');
        setEmail('');
      } else {
        setSubmitMessage(data.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      setSubmitMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="landing-page">
      <div className="background-blur"></div>
      <header>
        <div className="logo">AI Thumbnail</div>
        <nav>
          <a href="#about">About</a>
          <a href="#features">Features</a>
          <a href="#pricing">Pricing</a>
        </nav>
        <button className="shop-button">Try Now ↗</button>
        <div className="menu-icon">☰</div>
      </header>
      
      <main>
        <div className="content">
          <h2 className='prelaunch-h2'>Revolutionary AI-Powered Tool</h2>
          <h1 className='prelaunch-h1'>Create Stunning YouTube Thumbnails in Seconds</h1>
          <p className="subtitle">Say goodbye to hours of design work. Our AI generates eye-catching, click-worthy thumbnails tailored to your content.</p>
          <form onSubmit={handleSubmit} className="email-form">
            <input 
              type="email" 
              placeholder="Enter your email address" 
              value={email}
              onChange={handleEmailChange}
              required
            />
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Get Early Access'}
            </button>
          </form>
          {submitMessage && <p className="submit-message">{submitMessage}</p>}
          <p className="disclaimer">*Limited spots available. Join the waitlist now!</p>
        </div>
        
        <div className="app-preview">
          <div className="coming-soon-animation">
            {comingSoonText.map((line, lineIndex) => (
              <div key={lineIndex} className="animation-line">
                {line.split('').map((letter, letterIndex) => (
                  <span 
                    key={letterIndex} 
                    ref={el => lettersRef.current[lineIndex * line.length + letterIndex] = el}
                    className="balloon-letter"
                  >
                    {letter}
                  </span>
                ))}
              </div>
            ))}
          </div>
        </div>
      </main>
      
      <footer>
        <div className="social-icons">
          <FaFacebookF />
          <FaTwitter />
          <FaInstagram />
          <FaYoutube />
        </div>
        <div className="footer-links">
          <a href="">FAQ</a>
          <a href="">Privacy Policy</a>
          <a href="">Contact Us</a>
        </div>
      </footer>
    </div>
  );
};

export default PreLaunch;
