import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useUser } from './UserContext';
import DefaultLayout from './components/layout/DefaultLayout';
import Home from './components/Hero/Home';
import PreLaunch from './components/PreLaunch/PreLaunch';
import CheckoutPage from './pages/checkoutPage/CheckoutPage';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useUser();
  if (loading) return <div>Loading...</div>;
  return user ? children : <Navigate to="/" />;
};

const AppContent = () => {
  return (
    <Routes>
      <Route 
        path="/" 
        element={
          <DefaultLayout>
            <Home />
          </DefaultLayout>
        } 
      />
      <Route 
        path="/generate" 
        element={
          <ProtectedRoute>
            <DefaultLayout>
              <Home />
            </DefaultLayout>
          </ProtectedRoute>
        } 
      />
      <Route path="/subscribe" element={<PreLaunch />} />
      <Route path="/checkout" element={<CheckoutPage />} />
    </Routes>
  );
};

export default AppContent;