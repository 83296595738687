import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col, Spinner, ProgressBar } from "react-bootstrap";
import { Download, ChevronLeft, ChevronRight, ArrowRight, ChevronDown } from 'react-feather';
import { Element, scroller } from 'react-scroll';
import { FlipWords } from "../FlipWords";
import Features from "../Features/Features";
import Pricing from "../Pricing/Pricing";
import Banner from "../Banner/Banner";
import Constraints from "../Constraints/Constraints";
import "./Home.css";
import { useLocation } from 'react-router-dom';
import{ useUser } from '../../UserContext';
import { toast } from 'react-toastify';

import skate from "../../assets/skateboard_1.png";
import skin from "../../assets/skin_1.png";
import workout from "../../assets/workout_2.png";
import apache2 from "../../assets/apache_2.png";
import steak from "../../assets/steak_2.png";

const Home = () => {
    const location = useLocation();
    const [placeholder, setPlaceholder] = useState("");
    const staticText = "Give your YouTube video idea: ";
    const examples = ["Surviving one's 20s.", "How to do public speaking?", "Caligraphy 101"];
    const [exampleIndex, setExampleIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [isGenerating, setIsGenerating] = useState(false);
    const [showCurvedCarousel, setShowCurvedCarousel] = useState(false);
    const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
    const [activeCurvedCarouselIndex, setActiveCurvedCarouselIndex] = useState(1);
    const [isHovering, setIsHovering] = useState(false);
    const [userPrompt, setUserPrompt] = useState("");
    const [generatedImages, setGeneratedImages] = useState([]);
    const [selectedModel, setSelectedModel] = useState("basic");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [freeCredits, setFreeCredits] = useState(3);
    const totalCredits = 3;
    const { user } = useUser();
    const [generationProgress, setGenerationProgress] = useState(0);
    const [basicCredits, setBasicCredits] = useState(0);
    const [advancedCredits, setAdvancedCredits] = useState(0);

    useEffect(() => {
        const fetchCredits = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) {
                    throw new Error('No access token found');
                }
    
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/credits/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include'
                });
                
                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error('Unauthorized: Please log in again');
                    }
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
    
                const data = await response.json();
                const basicCredit = data.find(credit => credit.credit_type === 'basic');
                const advancedCredit = data.find(credit => credit.credit_type === 'advanced');
                
                setBasicCredits(basicCredit ? basicCredit.amount : 0);
                setAdvancedCredits(advancedCredit ? advancedCredit.amount : 0);
            } catch (error) {
                console.error('Error fetching credits:', error.message);
                if (error.message === 'Unauthorized: Please log in again') {
                    // Handle unauthorized error (e.g., redirect to login page)
                    // You might want to use your logout function here
                } else {
                    toast.error(`Failed to fetch credits: ${error.message}`);
                }
            }
        };
    
        fetchCredits();
    }, []);

    useEffect(() => {
        const fetchFreeCredits = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/credits/ip_credits/`);
                const data = await response.json();
                console.log('Free credits response:', data);

                if (response.ok) {
                    setFreeCredits(Math.min(data.amount, 3)); // Ensure free credits don't exceed 3
                } else {
                    console.error('Failed to fetch free credits:', data);
                }
            } catch (error) {
                console.error('Error fetching free credits:', error);
            }
        };

        fetchFreeCredits();
    }, []);

    const carouselImages = [
        { src: skate, alt: "skating tutorial", text: "Skating tutorial" },
        { src: skin, alt: "Korean skincare products review", text: "Korean skincare products review" },
        { src: workout, alt: "10 min workout routine", text: "10 min workout routine" },
        { src: apache2, alt: "How to use apache workflow", text: "How to use apache workflow" },
        { src: steak, alt: "Cooking a delicious steak", text: "Cooking a delicious steak" },
    ];

    const flipWords = ["Stunning", "Eye-catching", "Attractive", "Captivating", "Impressive"];

    const isGeneratePage = location.pathname === '/generate';

    useEffect(() => {
        if (location.state && location.state.scrollTo) {
            scroller.scrollTo(location.state.scrollTo, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown = (e) => {
        e.stopPropagation();
        console.log("Dropdown clicked");
        setDropdownOpen(prevState => {
            console.log("Dropdown state changing to:", !prevState);
            return !prevState;
        });
    };

    const handleModelSelect = (model) => {
        console.log("Model selected:", model);
        setSelectedModel(model);
        setDropdownOpen(false);
    };

    const handleGenerateClick = async () => {
        const credits = selectedModel === "basic" ? basicCredits : advancedCredits;
        if (credits > 0) {
            setIsGenerating(true);
            setGenerationProgress(0);
    
            // Simulate progress
            const simulateProgress = () => {
                setGenerationProgress(prevProgress => {
                    if (prevProgress >= 90) {
                        clearInterval(simulationInterval);
                        return prevProgress;
                    }
                    return prevProgress + 10;
                });
            };
            const simulationInterval = setInterval(simulateProgress, 500);
    
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) {
                    throw new Error('No access token found. Please log in again.');
                }
    
                console.log('Sending request with token:', token);
                console.log('Request payload:', JSON.stringify({
                    prompt: userPrompt,
                    model_type: selectedModel
                }));
    
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/images/generate/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        prompt: userPrompt,
                        model_type: selectedModel
                    }),
                    credentials: 'include'
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
    
                const data = await response.json();
    
                // Clear the simulation interval
                clearInterval(simulationInterval);
    
                // Set progress to 100% when done
                setGenerationProgress(100);
    
                if (data.images) {
                    setGeneratedImages(data.images);
                    setShowCurvedCarousel(true);
                    document.querySelector('.left-half')?.classList.add('move-up');
                    setBasicCredits(data.basic_credits_remaining);
                    setAdvancedCredits(data.advanced_credits_remaining);
                    toast.success('Images generated successfully!');
                }
            } catch (error) {
                console.error('Error generating images:', error);
                if (error.message.includes('401')) {
                    toast.error('Your session has expired. Please log in again.');
                    // You might want to call a logout function here or redirect to login page
                } else {
                    toast.error(`Failed to generate images: ${error.message}`);
                }
            } finally {
                // Clear the simulation interval if it's still running
                clearInterval(simulationInterval);
                setIsGenerating(false);
                setGenerationProgress(0);
            }
        } else {
            toast.error(`Oh no! You have no credits left for the ${selectedModel} model. Hurry up and buy!`);
        }
    };

    const handleDownload = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'generated-thumbnail.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading image:', error);
            toast.error('Failed to download image. Please try again.');
        }
    };

    useEffect(() => {
        if (charIndex < staticText.length) {
            const timeoutId = setTimeout(() => {
                setPlaceholder(staticText.slice(0, charIndex + 1));
                setCharIndex(charIndex + 1);
            }, 100);
            return () => clearTimeout(timeoutId);
        } else {
            const currentExample = examples[exampleIndex];
            if (charIndex < staticText.length + currentExample.length) {
                const timeoutId = setTimeout(() => {
                    setPlaceholder(staticText + currentExample.slice(0, charIndex - staticText.length + 1));
                    setCharIndex(charIndex + 1);
                }, 100);
                return () => clearTimeout(timeoutId);
            } else {
                const timeoutId = setTimeout(() => {
                    setCharIndex(staticText.length);
                    setExampleIndex((exampleIndex + 1) % examples.length);
                }, 2000);
                return () => clearTimeout(timeoutId);
            }
        }
    }, [charIndex, exampleIndex, examples, staticText]);

    const rotateCurvedCarousel = (direction) => {
        setActiveCurvedCarouselIndex((prevIndex) => {
            const newIndex = (prevIndex + direction + 3) % 3;
            return newIndex;
        });
    };



    const nextSlide = useCallback(() => {
        setActiveCarouselIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
    }, [carouselImages.length]);

    const prevSlide = () => {
        setActiveCarouselIndex((prevIndex) => (prevIndex - 1 + carouselImages.length) % carouselImages.length);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(interval);
    }, [nextSlide]);

    const bannerRef = useRef(null);

    const scrollToBanner = () => {
        bannerRef.current?.scrollIntoView({ behavior: 'smooth' });
    };


    const renderHomeContent = () => (
        <Row className="justify-content-center align-items-center min-vh-95 main-content">
            <Col xs={12} md={isGeneratePage ? 8 : 6} className={`${isGeneratePage ? 'text-center' : 'left-half'}`}>
                <h1 className={isGeneratePage ? "generate-heading" : ""}>
                    {isGeneratePage ? (
                        <>Hello, {user?.first_name && user?.last_name 
                            ? `${user.first_name} ${user.last_name}` 
                            : user?.username || 'User'}</>
                    ) : (
                        <>
                            <span className="first-line">
                                Create{" "}
                                <FlipWords
                                    words={flipWords}
                                    className="inline-block-flip"
                                />
                                {" "}YouTube
                            </span>
                            <span className="second-line">Thumbnails Instantly!</span>
                        </>
                    )}
                </h1>
                <p className={isGeneratePage ? "generate-subheading" : ""}>
                    {isGeneratePage ? "Let's create fun YouTube thumbnails!" : "Generate eye-catching thumbnails with just a few clicks!"}
                </p>
                <div className="thumbnail-generator">
                    <div className="orange-circle top-right"></div>
                    <div className="orange-circle bottom-left"></div>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            placeholder={placeholder}
                            className="thumbnail-generator-input"
                            value={userPrompt}
                            onChange={(e) => setUserPrompt(e.target.value)}
                            onFocus={() => setPlaceholder("")}
                            onBlur={() => setPlaceholder(staticText)}
                        />
                        <button className="generate-btn" onClick={handleGenerateClick} disabled={isGenerating || freeCredits === 0}>
                            {isGenerating ? (
                                <>
                                    <Spinner animation="border" size="sm" />  Generating
                                </>
                            ) : (
                                <>
                                    Generate <ArrowRight size={16} color="white" />
                                </>
                            )}
                        </button>
                    </div>
                    {isGenerating && (
                        <ProgressBar 
                            now={generationProgress} 
                            label={`${generationProgress}%`}
                            variant="warning"
                            animated
                            className="mt-3"
                        />
                    )}
                    <div className="model-and-credits">
                        <div className="model-selection" ref={dropdownRef}>
                            <div className="dropdown-toggle" onClick={toggleDropdown}>
                                {selectedModel === "basic" ? "Basic Model" : "Advanced Model"}
                            </div>
                            {dropdownOpen && (
                                <div className="dropdown-menu">
                                    <div className="dropdown-item" onClick={() => handleModelSelect("basic")}>
                                        Basic Model
                                        <span className="model-caption">High Quality</span>
                                    </div>
                                    <div className="dropdown-item" onClick={() => handleModelSelect("advanced")}>
                                        Advanced Model
                                        <span className="model-caption">Fast and High Quality</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="free-credits">
                    Free Credits: {freeCredits}/{totalCredits}
                </div>
                    </div>
                    {showCurvedCarousel && generatedImages.length > 0 && (
                        <div className="curved-carousel-container">
                            <div className="curved-carousel-main">
                                <div className="curved-carousel-outer">
                                    {generatedImages.map((image, index) => (
                                        <div
                                            key={image.id}
                                            className={`curved-carousel-item ${index === activeCurvedCarouselIndex ? 'active' : index === (activeCurvedCarouselIndex + 1) % generatedImages.length ? 'next' : 'prev'}`}
                                            onClick={() => rotateCurvedCarousel(index - activeCurvedCarouselIndex)}
                                        >
                                            <img src={image.image} alt={`Generated thumbnail ${index + 1}`} />
                                            {index === activeCurvedCarouselIndex && (
                                                <Download
                                                    className="curved-carousel-download-icon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDownload(image.image);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Col>
            {!isGeneratePage && (
                <Col xs={12} md={6} className="right-half">
                    <div
                        className="carousel-container book-page"
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                    >
                        {carouselImages.map((image, index) => (
                            <div
                                key={index}
                                className={`carousel-item ${index === activeCarouselIndex ? 'active' : ''}`}
                            >
                                <img src={image.src} alt={image.alt} />
                            </div>
                        ))}
                        {isHovering && (
                            <>
                                <button className="carousel-button prev" onClick={prevSlide}><ChevronLeft /></button>
                                <button className="carousel-button next" onClick={nextSlide}><ChevronRight /></button>
                            </>
                        )}
                    </div>
                    <p className="carousel-item-text">"{carouselImages[activeCarouselIndex].text}"</p>
                    <div className="carousel-dots">
                        {carouselImages.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === activeCarouselIndex ? 'active' : ''}`}
                                onClick={() => setActiveCarouselIndex(index)}
                            ></span>
                        ))}
                    </div>
                </Col>
            )}
        </Row>
    );

    return (
        <>
            <section id="home" className="home-section">
                <Container fluid>{renderHomeContent()}</Container>
            </section>
            {!isGeneratePage && (
                <>
                    <section id="features" className="min-vh-95">
                        <Features />
                    </section>
                    <section id="pricing" className="min-vh-95">
                        <Pricing onContactUs={scrollToBanner} />
                    </section>
                    <section id="constraints">
                        <Constraints />
                    </section>
                    <div ref={bannerRef}>
                        <Banner />
                    </div>
                </>
            )}
        </>
    );
};

export default Home;