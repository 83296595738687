import React from 'react'


function CheckoutPage() {
  const amount = 100;
  const currency = "INR";
  const receiptId = "testreceipt1";

  const payment = async (e) => {
     e.preventDefault(); // Prevent default form submission
  
  try {
    const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/order`, {
      method: "POST",
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check for non-JSON responses
    const respText = await resp.text();
    console.log("Response Text:", respText);

    // Attempt to parse JSON, but this may throw if it's not valid JSON
    const order = JSON.parse(respText);

    console.log(order);

    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID, 
      amount,
      currency,
      name: "Hacktogether",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.id,
      handler: async function (response) {
        const validateResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/order/validate`, {
          method: "POST",
          body: JSON.stringify(response),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const jsonResp = await validateResponse.json();
        console.log(jsonResp);
      },
      prefill: {
        name: "Gaurav Kumar",
        email: "gaurav.kumar@example.com",
        // contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  } catch (error) {
    console.error("Payment error:", error);
  }
  };
 

  return (
    <div>CheckoutPage
      <div>
        <button className='border' onClick={payment}> Pay now </button>
      </div>
    </div>
  )
}

export default CheckoutPage