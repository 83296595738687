import React, { useEffect, useState } from 'react';

export const AnimatedBeam = ({ containerRef, fromRef, toRef }) => {
  const [path, setPath] = useState('');

  useEffect(() => {
    const updatePath = () => {
      if (!containerRef.current || !fromRef.current || !toRef.current) return;

      const container = containerRef.current.getBoundingClientRect();
      const from = fromRef.current.getBoundingClientRect();
      const to = toRef.current.getBoundingClientRect();

      const fromX = from.left + from.width / 2 - container.left;
      const fromY = from.top + from.height / 2 - container.top;
      const toX = to.left + to.width / 2 - container.left;
      const toY = to.top + to.height / 2 - container.top;

      const midX = (fromX + toX) / 2;
      const midY = (fromY + toY) / 2;

      setPath(`M ${fromX},${fromY} Q ${midX},${midY} ${toX},${toY}`);
    };

    updatePath();
    window.addEventListener('resize', updatePath);
    return () => window.removeEventListener('resize', updatePath);
  }, [containerRef, fromRef, toRef]);

  return (
    <svg className="animated-beam" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', zIndex: 1 }}>
      <path d={path} fill="none" stroke="var(--color-primary)" strokeWidth="2" strokeDasharray="5,5" />
    </svg>
  );
};