import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Check, Star } from 'react-feather';
import './Pricing.css';
import SubscriptionHandler from './SubscriptionHandler';
import { useUser } from '../../UserContext';
import { toast } from 'react-toastify';

const PricingCard = ({ id, title, price, originalPrice, discountPercentage, description, features, isFirst, isLast, isPremium, isCustom }) => {
  const { user, updateUserCredits } = useUser();

  const handleSubscriptionSuccess = (subscription) => {
    updateUserCredits(subscription.plan.credits);
    toast.success(`Successfully subscribed to ${title} plan!`);
  };

  const handleSubscriptionError = (error) => {
    toast.error(`Subscription failed: ${error}`);
  };

  const handleContactUs = () => {
    const subject = encodeURIComponent("Custom Model Inquiry");
    const body = encodeURIComponent("Hello,\n\nI'm interested in a custom model for AIThumbnail. Could you please provide more information?\n\nThank you!");
    window.location.href = `mailto:support@hacktogether.dev?subject=${subject}&body=${body}`;
  };

  return (
    <Card 
      className={`pricing-card glassmorphic ${isFirst ? 'first-card' : ''} ${isLast ? 'last-card' : ''} ${isPremium ? 'premium-card' : ''}`}
    >
      {discountPercentage && !isFirst && <div className="discount-banner">{discountPercentage}% OFF</div>}
      <Card.Body>
        <h3>{title}</h3>
        {!isCustom ? (
          <>
            <h2>
              {originalPrice && <span className="original-price">${originalPrice}</span>} ${price}
            </h2>
            <p>{description}</p>
            {user ? (
              <SubscriptionHandler 
                planId={id} 
                amount={price * 100} 
                onSuccess={handleSubscriptionSuccess}
                onError={handleSubscriptionError}
              />
            ) : (
              <button className="try-out-btn" onClick={() => toast.info('Please log in to subscribe')}>Subscribe</button>
            )}
          </>
        ) : (
          <>
            <p>{description}</p>
            <button className="try-out-btn" onClick={handleContactUs}>Contact us</button>
          </>
        )}
        <div className="features">
          <div className="features-title">Features</div>
          <ul>
            {features.map((feature, index) => (
              <li key={index} className={feature.highlight ? 'highlight' : ''}>
                <div className="icon-circle">
                  {feature.highlight ? <Star size={14} /> : <Check size={14} />}
                </div>
                <span className="feature-text">
                  {feature.text}
                  {feature.replacement && <span className="replacement"> {feature.replacement}</span>}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

const Pricing = ({ onContactUs }) => {
  const pricingPlans = [
    { 
      id: 1,
      title: "Basic",
      price: "2.99",
      description: "10 credits",
      features: [
        { text: "10 Basic Model credits" },
        { text: "Limited Downloads" },
        { text: "Feature 3" },
      ],
    },
    { 
      id: 2,
      title: "Standard",
      price: "9.99",
      discountPercentage: 33,
      description: "50 credits",
      features: [
        { text: "25 Basic Model credits" },
        { text: "25 Advanced Model credits" },
        { text: "2x downloads" },
      ],
    },
    {
      id: 3,
      title: "Premium",
      price: "29.99",
      discountPercentage: 50,
      description: "200 credits",
      features: [
        { text: "50 Basic Model credits" },
        { text: "150 Advanced Model credits" },
        { text: "Unlimited downloads" },
        { text: "Early access to new features" },
        { text: "Most value for money", highlight: true },
      ],
    },
    {
      title: "Custom model",
      description: "Tailored to your needs",
      features: [
        { text: "Feature 1" },
        { text: "Feature 2" },
        { text: "Feature 3" },
      ],
      isCustom: true,
    },
  ];

  const handleBuyNow = (price) => {
    console.log(`Buy now clicked for price: $${price}`);
  };

  return (
    <section id="pricing" className="pricing-section">
    <Container>
      <h2 className="text-center mb-5">Pricing Plans</h2>
      <Row className="justify-content-center pricing-cards-row">
        {pricingPlans.map((plan, index) => (
          <Col key={index} lg={3} md={6} sm={12} className="pricing-card-col">
            <PricingCard 
              {...plan} 
              isFirst={index === 0} 
              isLast={index === pricingPlans.length - 1} 
              isPremium={index === pricingPlans.length - 2}
            />
          </Col>
        ))}
      </Row>
    </Container>
  </section>
  );
};

export default Pricing;