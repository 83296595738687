import React, { useState } from 'react';
import { FaGoogle } from 'react-icons/fa'; 
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../../UserContext';
import './Auth.css'; 

const SignUp = ({ onClose, onSignUp, setCurrentView, error }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [localError, setLocalError] = useState('');
  const navigate = useNavigate();
  const { login } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLocalError('');
    if (!username || !email || !password1 || !password2) {
      setLocalError('Please fill in all fields');
      return;
    }
    if (password1 !== password2) {
      setLocalError('Passwords do not match');
      return;
    }
    try {
      await onSignUp(username, email, password1, password2);
    } catch (error) {
      setLocalError(error.message);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        console.log('Google token:', tokenResponse);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/token-exchange/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ access_token: tokenResponse.access_token }),
          credentials: 'include',
        });
  
        const responseText = await response.text();
        console.log('Full response:', responseText);
  
        if (!response.ok) {
          let errorMessage;
          try {
            const errorData = JSON.parse(responseText);
            errorMessage = errorData.detail || 'Google login failed';
          } catch (e) {
            errorMessage = 'Unexpected server response';
          }
          throw new Error(errorMessage);
        }
  
        const data = JSON.parse(responseText);
        await login(data);
        onClose();
        toast.success('Logged in successfully with Google!');
        navigate('/generate');
      } catch (error) {
        console.error('Google login error:', error);
        toast.error(`Google login failed: ${error.message}`);
      }
    },
    onError: (error) => {
      console.error('Google login error:', error);
      toast.error('Google login failed. Please try again.');
    },
  });


  return (
    <div className="auth-popup">
      <h2>Create Your Account</h2>
      <button className="close-btn" onClick={onClose}>X</button>
      {(error || localError) && <p className="error">{error || localError}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          required
        />
        <button type="submit">Create Account</button>
      </form>
      <hr className="divider" />
      <button className="google-btn" onClick={() => handleGoogleLogin()}>
        <FaGoogle /> Continue with Google
      </button>
      <p>Already have an account? <span onClick={() => setCurrentView('signin')} className="link">Log In</span></p>
    </div>
  );
};

export default SignUp;