import React, { useState } from 'react';
import { Mail, Twitter } from 'react-feather'; 
import './Banner.css';

const Banner = () => {
  const [message, setMessage] = useState('');

  const handleSendMessage = () => {
    if (message.trim() !== '') {
      const mailtoLink = `mailto:support@hacktogether.dev?subject=Feedback&body=${encodeURIComponent(message)}`;
      window.location.href = mailtoLink;
    } else {
      alert('Please enter a message before sending.');
    }
  };

  return (
    <div className="contact-banner">
      <div className="banner-content">
        <div className="contact-info">
          <h2>GET IN CONTACT WITH US</h2>
        </div>
        <div className="contact-details">
          <div className="social-link">
            <Twitter />
            <a href="https://x.com/HacktogetherDev" target="_blank" rel="noopener noreferrer">HacktogetherDev</a>
          </div>
          <div className="email">
            <Mail />
            <p>support@hacktogether.dev</p>
          </div>
        </div>
        <div className="feedback">
          <h3>Your feedback is valuable to us</h3>
          <textarea 
            placeholder="Type your message here..." 
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={handleSendMessage}>Send Message</button>
        </div>
      </div>
    </div>
  );
};

export default Banner;