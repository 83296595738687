import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import { useUser } from '../../UserContext';
import './Auth.css';

const AuthPopup = ({ onClose, initialView = 'signin' }) => {
  const [currentView, setCurrentView] = useState(initialView);
  const { login } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('AuthPopup rendered. Initial view:', initialView);
  }, [initialView]);

  const handleSignIn = async (email, password) => {
    try {
      console.log('Attempting sign in for user:', email);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dj-rest-auth/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include', // This is crucial for receiving cookies
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.non_field_errors?.[0] || errorData.detail || JSON.stringify(errorData) || 'Login failed');
      }
  
      const data = await response.json();
      console.log('Sign in response:', data);
  
      await login(data);
      onClose();
      toast.success('Logged in successfully!');
      navigate('/generate');
    } catch (error) {
      console.error('Sign in error:', error);
      toast.error(`Sign in failed: ${error.message}`);
    }
  };

  const handleSignUp = async (username, email, password1, password2) => {
    try {
      console.log('Attempting sign up for user:', email);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dj-rest-auth/registration/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password1, password2 }),
      });

      const data = await response.json();
      console.log('Sign up response:', data);

      if (!response.ok) {
        if (data.email) {
          throw new Error(data.email[0]);
        } else if (data.username) {
          throw new Error(data.username[0]);
        } else if (data.password1) {
          throw new Error(data.password1[0]);
        } else {
          throw new Error(JSON.stringify(data) || 'Registration failed');
        }
      }

      toast.success('Registered successfully! Please log in.');
      setCurrentView('signin');
    } catch (error) {
      console.error('Sign up error:', error);
      toast.error(`Sign up failed: ${error.message}`);
    }
  };

  const handleGoogleLogin = async (tokenResponse) => {
    try {
      console.log('Google token response:', tokenResponse);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/token-exchange/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ access_token: tokenResponse.access_token }),
      });

      const data = await response.json();
      console.log('Google login response:', data);

      if (!response.ok) {
        throw new Error(data.detail || JSON.stringify(data) || 'Google login failed');
      }

      await login(data);
      onClose();
      toast.success('Logged in successfully with Google!');
      navigate('/generate');
    } catch (error) {
      console.error('Google login error:', error);
      toast.error(`Google login failed: ${error.message}`);
    }
  };

  const renderContent = () => {
    switch (currentView) {
      case 'signup':
        return <SignUp onClose={onClose} onSignUp={handleSignUp} onGoogleLogin={handleGoogleLogin} setCurrentView={setCurrentView} />;
      case 'forgotPassword':
        return <ForgotPassword onClose={onClose} setCurrentView={setCurrentView} />;
      case 'resetPassword':
        return <ResetPassword onClose={onClose} />;
      default:
        return <SignIn onClose={onClose} setCurrentView={setCurrentView} onSignIn={handleSignIn} onGoogleLogin={handleGoogleLogin} />;
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-btn" onClick={onClose}>X</button>
        {renderContent()}
      </div>
    </div>
  );
};

export default AuthPopup;